 
import { initializeApp } from "firebase/app"; 
import Cookies from 'js-cookie';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDlI5FYN0xMbBYycLw0S9kIBi7IyqK6gtY",
  authDomain: "kang-technology.firebaseapp.com",
  projectId: "kang-technology",
  storageBucket: "kang-technology.firebasestorage.app",
  messagingSenderId: "832071317672",
  appId: "1:832071317672:web:83469f5f53d036ccfc6a9d",
  measurementId: "G-BSLKCPN435"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const checkUserAuth = (setUser) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      saveUserToFirestore(user);  // Save or update Firestore data
      console.log("✅ User is logged in:", user.displayName);
      setUser(user);  // Automatically set user state

      Cookies.set("guid", user.uid, { expires: 7 });         
      Cookies.set("login_photoURL", user.photoURL, { expires: 7 });   
      Cookies.set("login_name", user.displayName, { expires: 7 });    
       

    } else {
      console.log("❌ No user logged in");
      setUser(null);
    }
  });
};
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    console.log("✅ User Info:", result.user);
    return result.user;  // Return user data
  } catch (error) {
    console.error("❌ Google Sign-In Error:", error);
  }
};

// Google Logout function
export const googleLogout = async () => {
  try {
    await signOut(auth);
    Cookies.remove("guid");
    Cookies.remove("login_photoURL");
    Cookies.remove("login_name");
    console.log("✅ Logged out successfully");
  } catch (error) {
    console.error("❌ Logout Error:", error);
  }
};
export const saveUserToFirestore = async (user) => {
  if (!user) return;

  const userRef = doc(db, "account", user.uid);

  // Check if the user already exists
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    // 🔥 If user exists → Update photoURL and login time
    await updateDoc(userRef, {
      photoURL: user.photoURL,                // Update latest profile picture
      login_datetime: new Date().toISOString()  // Store the latest login time
    });
    console.log("✅ User data updated:", user.displayName);
  } else {
    // 🔥 If new user → Save to Firestore
    await setDoc(userRef, {
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      login_datetime: new Date().toISOString(),   // First login timestamp
      createdAt: new Date().toISOString()         // Creation timestamp
    });
    console.log("✅ New user saved to Firestore:", user.displayName);
  }
};

export { db };