import { Routes, Route, link} from 'react-router-dom';
import './App.css';
import Homeheader from './body_roots/Home_header';
import Homefooter from './body_roots/Home_footer'
import Home from './pages/home'; 

import About from './pages/about'; 
import Search from './pages/search'; 
import Support from './pages/support';
import Termsofcondition from './pages/terms-of-condition'
function App() {
 

  return (
    <div className="App">  
 <Homeheader/>
 <div className='app_header_height'></div>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/search" element={<Search />} />
        <Route path="/support" element={<Support />} />
        
        <Route path="/terms-of-condition" element={<Termsofcondition />} />
      </Routes> 

      <Homefooter/>
    </div>
   
  );
}
export default App;
