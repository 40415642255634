 

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { db } from "../firebase";  // Import Firestore configuration
import { addDoc, collection } from "firebase/firestore";
import { signInWithGoogle, googleLogout, checkUserAuth } from '../firebase';
import './App_header.css';
import '../pages/app_login.css';
import '../pages/app_product.css';
import menu_img from '../assets/menu_24dp_000000_FILL1_wght300_GRAD0_opsz24.png';
import logo from '../assets/kangin-logo.png';
import search_img from '../assets/search_24dp_000000_FILL1_wght300_GRAD0_opsz24.png';
import login_settings from '../assets/photos_icon.png';
import apps_img from '../assets/apps_24dp_000000_FILL0_wght300_GRAD0_opsz24.png';
import ToggleSwitch from "../components/ToggleSwitch";
import Login from '../pages/login';
import Product from '../pages/product';
export default function Homeheader() {
    const messageRef = useRef();
    const navigate = useNavigate(); 
    const [user, setUser] = useState(null);

  // Automatically check for logged-in user on page load



    //    const dataRef = collection(db, "data_store");

    //    const handleSave = async (e) => {
    //     e.preventDefault();
    //     const message = messageRef.current.value;

    //     if (message.trim() === "") {
    //       alert("Please enter a message!");
    //       return;
    //     }

    //     try {
    //       await addDoc(dataRef, { message: message });
    //       console.log("Data saved:", message);
    //       alert("Message saved successfully!");
    //       messageRef.current.value = "";  // Clear input field
    //     } catch (error) {
    //       console.error("Error saving message:", error);
    //     }
    //   };



    const homeClick = () => {
        navigate('/');  // Navigate to another route
      };
      const aboutClick = () => {
        navigate('/about');  // Navigate to another route
      };
      const searchClick = () => {
        navigate('/search');  // Navigate to another route
      };
    const supportClick = () => {
        navigate('/support');  // Navigate to another route
      };

      //login
      const [loginisOpen, loginsetIsOpen] = useState(false);   // State for popup
      const popupRef = useRef(null);    // Ref for direct DOM manipulation
    
      const openLoginPopup = () => {
        loginsetIsOpen(true);
      };
    
      const closeLoginPopup = () => {
        loginsetIsOpen(false);
      };
      
      //prod
      const [isOpen,setIsOpen] = useState(false); 
      const ProductopenPopup = () => {
        setIsOpen(true);   
      };    
      const ProductclosePopup = () => {
        setIsOpen(false); 
      };
      useEffect(() => {
        checkUserAuth(setUser);  // Auto-login
        const existingUser = Cookies.get("guid");   // Check for existing login
        if (existingUser != null) {
            loginsetIsOpen(false);
        } else {        
          loginsetIsOpen(true);   // Auto-open login popup if not logged in
        }
      }, []);
    return (
    
        <div>
            
            <header className="App_header">
                
                <div className="left_div">
                {/* <img className="menu_img"
                
                 src={menu_img}></img> */}
                <img className="logo"  onClick={homeClick} src={logo} alt="Logo" />
                </div>
                <div className="center_body">
                    <a className="link_btn" onClick={aboutClick}>About                   
                    </a>
                    <a className="link_btn" onClick={supportClick}>Support                     
                    </a>
                </div>
                <div className="right_div"> 
               
                <img className="icon_img" onClick={searchClick} src={search_img}></img>
                <img className="icon_img"  onClick={ProductopenPopup}  src={apps_img}></img>
                {user && user.photoURL? (                   
                    <img className="login_settings" onClick={openLoginPopup} src={user.photoURL}></img>     
                ) : (
                    <div className="login_button" onClick={openLoginPopup}  >Login</div> 
                  )}   
                     
                </div>
            </header>
         
            {loginisOpen && (
        <div className="login_popup-overlay" onMouseLeave={closeLoginPopup} onClick={closeLoginPopup}>
          <div className="login_popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="login_close-btn" onClick={closeLoginPopup}>✖</button>
            <Login />  
          </div>
        </div>
      )}

 
            {isOpen && (
        <div className="popup-overlay" onClick={ProductclosePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={ProductclosePopup}>✖</button>
            <Product />  
          </div>
        </div>
      )}
        </div>
    );
}
