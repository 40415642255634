import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { db } from "../firebase";  // Import Firestore configuration
import { addDoc, collection } from "firebase/firestore";
import './App_home_footer.css';
export default function Homefooter() {
      const messageRef = useRef();
        const navigate = useNavigate();
    //    const dataRef = collection(db, "data_store");

    //    const handleSave = async (e) => {
    //     e.preventDefault();
    //     const message = messageRef.current.value;

    //     if (message.trim() === "") {
    //       alert("Please enter a message!");
    //       return;
    //     }

    //     try {
    //       await addDoc(dataRef, { message: message });
    //       console.log("Data saved:", message);
    //       alert("Message saved successfully!");
    //       messageRef.current.value = "";  // Clear input field
    //     } catch (error) {
    //       console.error("Error saving message:", error);
    //     }
    //   };

    const TermsOfConditionClick = () => {
        navigate('/terms-of-condition');  // Navigate to another route
      };

    const year = new Date().getFullYear();   // ✅ Use .getFullYear()


    return (
        <div>
            <div className="app_home_footer"> 
            </div>
            <div className="tearms_body">
                <a className="compny">&#169; KANGIN 2002 - {year} All rights reserved</a>   {/* Display current year */}
            <a className="tearms_links" onClick={TermsOfConditionClick}>Terms Of Condition</a>           
            
            </div>
        </div>
    );
}