import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from "../firebase";  // Import Firestore configuration
import { signInWithGoogle, googleLogout, checkUserAuth } from '../firebase';
import { addDoc, collection } from "firebase/firestore";
import './app_login.css';
import Googleicon from '../assets/google_icon.png';
export default function Login() {
  //const messageRef = useRef();
    const navigate = useNavigate(); 
  const [user, setUser] = useState(null);
  const [guid, setGuid] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [loginName, setLoginName] = useState("");
  // Automatically check for logged-in user on page load
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    checkUserAuth(setUser);  // Auto-login
    // setGuid(Cookies.get("guid"));
    // setPhotoURL(Cookies.get("login_photoURL"));
    // setLoginName(Cookies.get("login_name"));
  }, []);
  const handleGoogleLogin = async () => {
    const userData = await signInWithGoogle();
    setUser(userData);    // Set user state
  };

  const handleGoogleLogout = async () => {
    await googleLogout();
    setUser(null);
    // setGuid("");
    // setPhotoURL("");
    // setLoginName("");
  };
  //  const dataRef = collection(db, "data_store");

  //  const handleSave = async (e) => {
  //   e.preventDefault();
  //   const message = messageRef.current.value;

  //   if (message.trim() === "") {
  //     alert("Please enter a message!");
  //     return;
  //   }

  //   try {
  //     await addDoc(dataRef, { message: message });
  //     console.log("Data saved:", message);
  //     alert("Message saved successfully!");
  //     messageRef.current.value = "";  // Clear input field
  //   } catch (error) {
  //     console.error("Error saving message:", error);
  //   } onSubmit={handleSave}
  // };
 
 
  const login_supportClick = () => {
    navigate('/support');  // Navigate to another route
  };
  return (
    <div>
{user ? (
        <div>
          <table>
          <tr >
              <td >
              <img src={user.photoURL} alt="User" className="profile-pic" />
              </td>
            </tr>
            <tr >
              <td className='boder_box'>
              <p className='user_name'>Welcome, <b>{user.displayName}</b></p>
              </td>
              </tr>
              <tr >
              <td className='boder_box'>
              <p className='user_name'>Email: {user.email}</p>
              </td>
              </tr>
              <tr >
              <td className='boder_box cur'>
              <p className='user_name'>Profile Setting</p>
              </td>
              </tr>
              <tr >
              <td className='boder_box cur' onClick={login_supportClick}>
              <p className='user_name'>Support</p>
              </td>
              </tr>
              <tr>
              <td>
              <div className='logout_button' onClick={handleGoogleLogout}>Logout</div>
              </td>
              </tr>
          </table>
         
      
  
         
        </div>
      ) : (
        <div className='login_button' onClick={handleGoogleLogin}>
           <img src={Googleicon}   className="googleicon-pic" />
          <a className='google_name'>Login with Google</a>
          </div>
      )}
    </div>
    
  );
}
