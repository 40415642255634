import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { db } from "../firebase";  // Import Firestore configuration
import { addDoc, collection } from "firebase/firestore";

export default function Search() {
  const messageRef = useRef();
  //  const dataRef = collection(db, "data_store");

  //  const handleSave = async (e) => {
  //   e.preventDefault();
  //   const message = messageRef.current.value;

  //   if (message.trim() === "") {
  //     alert("Please enter a message!");
  //     return;
  //   }

  //   try {
  //     await addDoc(dataRef, { message: message });
  //     console.log("Data saved:", message);
  //     alert("Message saved successfully!");
  //     messageRef.current.value = "";  // Clear input field
  //   } catch (error) {
  //     console.error("Error saving message:", error);
  //   } onSubmit={handleSave}
  // };

  return (
    <div className="container_port"> Search
 
    </div>
    
  );
}
